import logo from './profile_photo.jpeg';

import './App.css';

function App() {
  return (

    <body className="App-header">
      <div className=" col-10" >
        <div className="row col-12 text-white header">
          <div className="col">
          <p>Kaamesh Kamalaaharan</p>
          <p>Sofware Engineer , Developer , Team Player</p>
          </div>
       

        </div>
        <div className="row col-12 summary-col " >
          <div className=" col col-3">
            <div className="row">
              <div className="col ">
                <img src={logo} className="profile-photo" alt="" />
              </div>
            </div>
            <div className="row">
              <span>
                Contact Details
              </span>
              <div className="contact-details-text">
              <p>
                kaamesh.kamalaaharan@gmail.com
              </p>
              

              </div>
             
            </div>
            <div className="row">
              <span>
                Fluent in 
              </span>
              <div className="contact-details-text">
                <ul>
                <li>
                Python - Django, Postgres, Redis, Celery
              </li>
             < li>
                Javascript - React, Express, Node, Postgres, Mongo
              </li>

             <li>
                Cloud services - GCP, AWS, AliCloud
              </li>

              <li>
                Systems Administration - Unix Systems
              </li>

              <li>
                Team Management - JIRA, Trello
              </li>

                </ul>
              
              </div>
             
            </div>
          </div>

          <div className="col details-section  ">
            <div className="row">
              <div className="col">
                <p className="details-section-header" >About Me</p>
              
  <p className="details-section-text"> I am a Software Engineer with 10 years of experience in various fields. Specializing in project management and software design. I am a capable back end developer , well versed in Python and Javascript with a career's worth of experience in System Administration, Hardware Integration, Design, Research and Analysis and Client Communications. </p>
  <p className="details-section-text">If you are looking for a fullstack developer capable of planning and designing a suitable solution for your needs, as well as someone capable of sourcing the right talent while managing them, I'm your guy!</p>
</div>
</div>
            <div className="row">
              <div className="col">
                <p className="details-section-header">Work Experience</p>

              <div className="row">
                <div className="col">
                  <p className="details-section-header-1">Novocraft Technologies Sdn Bhd </p>
                  <p className="details-section-header-2"> 2012 - 2020</p>
                  <p className="details-section-header-3">Bioinformatics Software Engineer - Development Team Leader</p>
                  <p className="details-section-text">Tasks </p>
                  <p className="details-section-text"><ul>
                    <li>Development of bioinformatics software pipelines</li>
                    <li>Intergration of toolkits and diagnostics pipelines into a user friendly platform</li>
                    <li>Cloud migration of existing platform</li>
                    <li>Development of an all-in-one system for diagnostics and analytics</li>
                    <li>Manage and lead a team of bioinformaticians/biostatisticians in development of new pipelines</li>

                    </ul></p>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <p className="details-section-header-1">Harumio Pte Ltd</p>
                  <p className="details-section-header-2"> 2020 - 2022 </p>
                  <p className="details-section-header-3">Head of Software Development - Ecommerce</p>
                  <p className="details-section-text">Tasks</p>
                  <p className="details-section-text"><ul>
                    <li>Develop and deploy E-commerce platforms for clients - Django/Javascript</li>
                    <li>Design ,develop and manage warehouse management systems for different locales </li>
                    <li>Design ,develop and manage  Order Management Systes for different locales</li>
                    <li>Design ,develop and manage integrated platform for Order, Warehouse, Fulfilment, and Administration deployed as a service </li>
                  </ul></p>
                  
                </div>
              </div>
             
              </div>
            </div>

          </div>
        </div>
      </div>


    </body>
  );
}

export default App;
